/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const dispatchEditStore = reactive({
	// *** Edit Dispatch Headers
	dispatchExchangeRate: '',
	dispatchExchangeUnit: '',
	dispatchType: '',
	ourCompany: '',
	customer: '',
	dispatchAddress: '',
	selectedDispatchNumber: '',
	selectedDispatchDate: '',
	keySubOrdersToDispatch: 1,
	showGetDispatchReport: false,
	// ! ---------------------------------------------------
	// * for EditDispatch.vue tables rows
	orders: [],
	selectedOrders: [],

	// ! ------------------------------------------------------------------------
	orderTableColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Customer Title',
			field: 'customer_title',
		},
		{
			label: 'Customer Nickname',
			field: 'customer_nickname',
		},
		{
			label: 'Customer ID',
			field: 'customer_id',
			width: '135px',
		},
	],
	// ! --------------------------------------------------------------------------
	selectOrderToDispatchColumns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Order Type',
			field: 'order_type',
			width: '115px',
		},
		{
			label: 'Order Exchange',
			field: 'order_exchange_unit',
			width: '115px',
		},
		{
			label: 'Address Nickname',
			field: 'order_headers_dispatch_address_addresses_rel.address_nickname',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
	// ! --------------------------------------------------------------------------
	selectedOrdersColumns: [
		{
			label: 'Remove',
			field: 'remove',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Order Number',
			field: 'order_number',
			width: '110px',
		},
		{
			label: 'Order Date',
			field: 'order_date',
			width: '135px',
		},
		{
			label: 'Order Type',
			field: 'order_type',
			width: '115px',
		},
		{
			label: 'Order Exchange',
			field: 'order_exchange_unit',
			width: '115px',
		},
		{
			label: 'Address Nickname',
			field: 'order_headers_dispatch_address_addresses_rel.address_nickname',
		},
		{
			label: 'Preview',
			field: 'preview',
			width: '50px',
			sortable: false,
		},
	],
})
const variablesQ2 = reactive({
	company_id: '',
	customer_id: '',
	dispatch_address_id: '',
	invoice_address_id: '',
	order_exchange_rate: 1,
	order_exchange_unit: 'kr.',
	order_type: '',
})
export default function useDispatchEditStore() {
	return {
		...toRefs(dispatchEditStore),
		variablesQ2,
	}
}
